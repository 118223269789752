import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Container } from '../components/container'
import { Layout } from '../components/layout'
import { SlideCaption, Slider } from '../components/slider'

const ESGPage: FC = () => {
  const { t } = useTranslation('esg')

  return (
    <Layout title={t('documentTitle')} activePage="/esg/">
      <Slider interval={4000}>
        <>
          <StaticImage
            src="../assets/images/esg-slider1.jpg"
            alt=""
            layout="fullWidth"
            objectPosition="top right"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.first')}</small>
          </SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/esg-slider2.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.second')}</small>
          </SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/esg-slider3.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.third')}</small>
          </SlideCaption>
        </>
      </Slider>

      <HighlightSection>
        <Container>{t('highlightText')}</Container>
      </HighlightSection>

      <ContentSection>
        <Container>
          <Header>{t('environment')}</Header>
          <Text>{t('environmentText')}</Text>

          <Header>{t('social')}</Header>
          <Text>{t('socialText')}</Text>

          <Header>{t('governance')}</Header>
          <Text>{t('governanceText')}</Text>
        </Container>
      </ContentSection>
    </Layout>
  )
}

export default ESGPage

const HighlightSection = styled.section`
  ${({
    theme: {
      colors: { brand },
      typography: { raleway },
    },
  }) => css`
    background-color: ${brand};
    color: white;
    font-family: ${raleway};
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.875rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  `}
`

const ContentSection = styled.section`
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
`

const Header = styled.h2`
  ${({
    theme: {
      colors: { brand },
      typography: { raleway },
    },
  }) => css`
    color: ${brand};
    font-family: ${raleway};
    font-weight: 600;
    font-size: 1.5rem;
  `}
`

const Text = styled.p`
  ${({
    theme: {
      typography: { raleway },
    },
  }) => css`
    font-family: ${raleway};
    font-weight: 600;
    font-size: 1.125rem;
  `}
`
